import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userAPI";
import {Spinner} from "react-bootstrap";
import './css/mainPage.css';
import './css/Footer.css';
const App = observer(() => {
   

   

    return (
        <BrowserRouter>
         
            <NavBar />  
            
 
            <AppRouter /> 
            <Footer/>
        </BrowserRouter>
        

    );
});

export default App;
